import React, { useEffect, useState } from "react";
import {
	loginKombit,
	getKombitDetails,
	getKombitShortcut,
} from "../../utils/KombitUtils";
import { LOGIN_MUTATION } from "../../graphql/graphqlMutations";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { setUser, setLoggedIn, setKMDAuth } from "../../redux/slices/userSlice";
import { getKmdToken, loginKmd } from "../../utils/KmdUtils";
import { useSelector } from "react-redux";
import { useKMD } from "../../config";
import backGroundImage from "../../assets/headerBackground.jpeg";
import KintellaSvg from "../../assets/KintellaSvg";
import { dashboardRoute, residentsRoute, selectOrganizationRoute } from "../../routes/mainRoutes";
import KLoader from "../../components/KLoader";
import AdminLogin from "./AdminLogin";

export default function LoginPage({ history, location }) {
	const user = useSelector((state) => state.user);

	const dispatch = useDispatch();
	const [doLogin, { loading, error }] = useMutation(LOGIN_MUTATION);

	const [adminModalOpen, setAdminModalOpen] = useState(false)
	const [errorMessage, setErrorMessage] = useState();

	useEffect(() => {
		const kInfo = getKombitDetails();
		if (kInfo.verified) {
			login(
				kInfo.payload.email,
				kInfo.payload.nameId,
				kInfo.payload.role
			);
		}
	}, []);

	// Listens on redux user state, and pushes to dashboard if user changes to authenticated
	useEffect(() => {
		if (user.loggedIn && !user.KMDAuth && useKMD) {
			return loginKmd(user.data.municipality);
		}

		if (user.loggedIn) {
			if (user.data.organization === null) {
				history.push(selectOrganizationRoute, {
					user: user,
					role: user.data.role.name
				})
			} else {
				history.push(residentsRoute);
			}
		}
	}, [user.loggedIn]);

	// listen for KMD token, and set loggedIn to redirect to home
	useEffect(() => {
		const token = getKmdToken();
		if (token?.isValid) {
			dispatch(setKMDAuth(token));
			dispatch(setLoggedIn(true));
		}
	}, []);

	const login = async (email, password, role) => {
		doLogin({
			variables: {
				loginInput: {
					email: email,
					password: password,
				},
			},
		})
			.then((d) => {
				var tmp = { ...d.data.login };
				// tmp.user.role.name = role;

				// if (tmp.user.organization === null) {
				// 	// dispatch(setUser(tmp));
				// 	// dispatch(setLoggedIn(true));
				// 	history.push(selectOrganizationRoute, {
				// 		user: tmp,
				// 		role: role
				// 	})
				// } else {
				dispatch(setUser(tmp));
				dispatch(setLoggedIn(true));
				// }
			})
			.catch((e) => setErrorMessage(e));
	};

	if (loading) {
		return (
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
				<KLoader />
			</div>
		)
	}

	if (error) {
		<p>{error}</p>;
	}

	const KLButton = ({ onClick, type, label }) => (
		<button
			onClick={onClick}
			className={`broder-white border hover:bg-lightPurple h-10 px-10 py-1 w-max`}
			type={type}
		>
			<p className="text-white">{label}</p>
		</button>
	);

	return (
		<div
			className="flex flex-row items-center flex-1 h-screen text-center text-white bg-center bg-cover"
			style={{ backgroundImage: `url(${backGroundImage})` }}
		>
			<div className="w-full space-y-8">
				<div className="m-auto w-min">
					<p style={{ fontFamily: 'MetricBold' }} className="pb-4">LOG IND PÅ</p>
					<div className="w-72">
						<KintellaSvg />
					</div>

					<AdminLogin open={adminModalOpen} setOpen={setAdminModalOpen} />
					<div onClick={() => setAdminModalOpen(true)} className="absolute px-2 py-1 border border-white cursor-pointer hover:border-gray-200 top-5 right-5 hover:text-gray-200">
						<p className="text-xs">Admin login</p>
					</div>

					<div className="pt-10 mx-auto space-y-2 w-min">
						<KLButton
							onClick={loginKombit}
							type="submit"
							label="KOMBIT LOGIN"
						/>
					</div>
					<p className="pt-8 mx-auto text-xs w-max">
						COPYRIGHT KINTELLA 2022
					</p>
					{errorMessage && (
						<p className="w-48 pt-8 mx-auto text-red-600">
							{errorMessage.message}
						</p>
					)}
				</div>
			</div>
		</div>
	);
}